import React from "react";
import './styles.css'
import Header from "../../../components/header/header";
import Banner from "../../../components/banner/banner";
import Footer from "../../../components/footer/footer";
import ICaseiImg from '../../../assets/iCasei2.png'

function Icasei(){
    return(
        <>
        <Header />
        <Banner />
        <div className="espaco-extra"></div>
        <div className="espaco-extra"></div>
        <div className="title-page">
            <span className="title-text">
                Lista de Presentes
            </span>
        </div>
        <iframe id="myIframe" src="https://sites.icasei.com.br/eduardo_anna_2025/pages/show/33498341" width="100%" height="900" frameBorder="0"
        title="iCasei - Lista de Presentes"/>
        <div className="icasei-img-main">
            <div className="icasei-text">
                <span>Lista de presentes por:</span>
            </div>
            <div className="icasei-img-link">
                <a href="https://www.icasei.com.br/" target="_blank">
                    <img id='icasei-img' src={ICaseiImg} alt="iCasei logo img"></img>
                </a>
            </div>
        </div>
        <div className="espaco-presenca"></div>
        <Footer />
        </>
    )
}

export default Icasei;