import React from "react";
import './styles.css'
import Header from "../../components/header/header";
import Banner from "../../components/banner/banner";
import Footer from "../../components/footer/footer";
import ICaseiImg from '../../assets/iCasei2.png'
import LogoThaisImg from '../../assets/LogoThaiKampai.png'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShoePrints} from '@fortawesome/free-solid-svg-icons'

function Presenca(){
    return(
        <>
        <Header />
        <Banner />
        <div className="espaco-presenca"></div>
        <div className="espaco-presenca"></div>
        <div className="title-page">
            <span className="conf-title">
                Confirmação de Presença
            </span>
        </div>
        <div className="espaco-presenca"></div>
        <div className="conf-instrução">
            <span className="conf-text">
                Pedimos a gentileza de confirmarem se poderão estar presente no nosso grande dia.
                <br/>
                Para isso preencher o campo abaixo, chamado nome, com o nome como está no convite e continuar.
                <br/>
                *Para quem recebeu o convite digital colocar o nome como está após underline (_) no nome do arquivo, por exmplo: ConviteDigital_NomeDoConvidado
                <br/>
                No campo de grupo selecionar Adulto. Para a pergunta "Quantos acompanhantes" informar o total de adultos e crianças.
                <br/>
                Preencher com o nome e sobrenome de cada um que vai.
                <br/>
                Caso tenha ficado aguma dúvida de como proceder, no ícone abaixo tem a página com um passo a passo.
            </span>
            <div className="espaco-presenca"></div>
            <Link style={{textDecoration:'none'}} to='/presenca-step-by-step'>
                <FontAwesomeIcon icon={faShoePrints} className="icon-presenca" />
                <FontAwesomeIcon icon={faShoePrints} className="icon-presenca" />
                <FontAwesomeIcon icon={faShoePrints} className="icon-presenca" />
            </Link>
            <div className="espaco-presenca"></div>
            <span className="conf-text">
                * A confirmação final será feita entre o final de Dezembro e Janeiro
                <br/>
                pela equipe da nossa cerimonialista, Thais Kampai.
            </span>
            <a href="https://www.instagram.com/thaiskampai?igsh=cm42MG0zNGNiNW91" target="_blank">
                <img id='logoThais-img' src={LogoThaisImg} alt="logo Thais Kampai img"></img>
            </a>
            <div className="espaco-presenca"></div>
            <div className="espaco-presenca"></div>
        </div>
        
        <iframe id="myIframe-CP" src="https://sites.icasei.com.br/eduardo_anna_2025/pages/show/33498224" width="100%" height="900" frameBorder="0"
        title="iCasei - confirmação de presença"/>
        <div className="conf-instrução">
            <span className="conf-text">
                *Após confirmar sua presença o iCasei vai enviar um e-mail informando os dados preenchidos,
                <br/> e o número de convidados confirmados, ele conta o nome do convite como uma pessoa a mais,
                <br/> mas vamos usar apenas os nomes completos na lista da festa. 
            </span>
        </div>
        <div className="espaco-presenca"></div>
        <div className="icasei-img-main">
            <div className="icasei-text">
                <span>Confirmação de presença por:</span>
            </div>
            <div className="icasei-img-link">
                <a href="https://www.icasei.com.br/"  target="_blank">
                    <img id='icasei-img' src={ICaseiImg} alt="iCasei logo img"></img>
                </a>
            </div>
        </div>
        <div className="espaco-presenca"></div>
        <Footer />
        </>
    )
}

export default Presenca;